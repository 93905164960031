export default {
  TOKEN_GOOGLE: 'AIzaSyAvSh7adPw6XbdBh1P32OjSNL5b5ldLci4',
  // TOKEN_GOOGLE: 'AIzaSyDBFQMWKZCIaCLget5nFcoHL5fjfl0WyU0',
  TOKEN_MAPBOX:
    'pk.eyJ1IjoibmlyZnIiLCJhIjoiY2pwMTBydjNtMmplazNrczNpYjYya3N0eSJ9.-TtAX7FNNq5bQMwnF8OduA',
  markerSize: 18,
  icons: {
    antenna: require('@/assets/images/mapView_icons/antenna.svg'),
    cabinet: require('@/assets/images/mapView_icons/black_cabinet.svg')
  }
};
