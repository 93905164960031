



















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import json2xls from 'json2xls';
import VuePapaParse from 'vue-papa-parse';
import API, { Types } from '@/modules/API';
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import { Global } from '@/store';

Vue.use(VuePapaParse);
@Component({})
export default class ExportData extends Vue {

  @Prop({ default: () => [] }) data;
  @Prop() network_data;
  @Prop() color;
  @Prop() sourceTitle?: string;
  @Prop() isDisabled: boolean;
  @Prop() noParsing: boolean;

  formats = ['csv', 'xlsx', 'json'];
  exportedData = [];
  loading = false;
  headersMap = {
    group: 'Group',
    poleNumber: 'Pole Number',
    cabinetId: 'Cabinet Id',
    timestamp: 'Last Data Update',
    Network: 'Network',
    IMSI: 'IMSI',
    Cellular_Type: 'Cellular_Type',
    MCC: 'MCC',
    MNC: 'MNC',
    LAC: 'LAC',
    Cell_ID: 'Cell_ID',
    Communication_quality_grade: 'Quality Grade',
    RSSI: 'RSSI [dBm]'
  };

  async exportData(format) {
    this.loading = true;
    this.exportedData = [];
    await this.createExportedData();

    const filename = this.sourceTitle + ' - ' + Utils.getDateString();
    let data;

    switch (format) {
      case 'json':
        data = this.sourceTitle ? JSON.stringify([{source : this.sourceTitle}, ...this.exportedData], null, '\t') : JSON.stringify(this.exportedData, null, '\t');
        break;

      case 'csv':
        // @ts-ignore
        data = this.$papa.unparse(this.exportedData);
        break;

      case 'xlsx':
        data = json2xls(this.exportedData);
        break;
    }

    this.download(filename, format, data);
    this.loading = false;
  }

  download(filename, format, data) {
    if (data) {
      const blob = new Blob([data], { type: 'text/plain;charset=utf-8;' });
      const link = document.createElement('a');
      let url = null;
      if (format === 'xlsx') {
        url = 'data:application/octet-stream;charset=utf-16le;base64,' + btoa(data);
      }
      else {
        url = URL.createObjectURL(blob);
      }
      link.setAttribute('href', url);
      link.setAttribute('download', filename + '.' + format);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  async createExportedData() {
    if (this.noParsing) {
      this.exportedData = this.data;
    }
    else {
      await Promise.all(this.data.map(async (report) => {
        let dataToExport = {};
        try {
            let lastSysActiveDate = '';
            if (report['sys___active'] != null && Number(report['sys___active']) === 0) {
                const res = await this.getStreamChangeTime(report.id, 'sys___active');
                lastSysActiveDate = res;
            }
            const commissionProp = report['meta.commission'];
            const info = {
                'ID': report.id,
                'Name': report.name,
                'Class Name': report['class_name'],
                'Commission':  commissionProp['commissioned'],
                'Commission Date': commissionProp['date'],
                'Address': Utils.deviceLocation(report), 
                'Status': lastSysActiveDate ? `Inactive since ${lastSysActiveDate}` : 'Active',
                'sys_active': report['sys___active'],
                'sys_online': report['sys___online'],
                'sys_connected': report['sys___connected'],
                'Power': report['power']
            };
            if (Utils.hasCabinetClass(report.class_name) || Utils.hasVirtualCabinetClass(report.class_name, report['meta.device'])){
              let mac_id = '';
              if (report.class_name.includes('sbc_cabinet')){
                mac_id = PropUtils.getTeltonicaHardwareid(report);
              }else {
                const hardwareid_prop = PropUtils.parseProperty('device.hardwareid', report);
                mac_id = hardwareid_prop && hardwareid_prop.id || '';
              }
              info['CPU ID'] = '';
              info['MAC'] = mac_id;
              info['IMEI'] = '';
            }else {
              const hardwareidProp = PropUtils.parseProperty('device.hardwareid', report);
              if (hardwareidProp){
                info['CPU ID'] = hardwareidProp.id ? hardwareidProp.id : '';
                info['MAC'] = hardwareidProp.mac ? hardwareidProp.mac : '';
                info['IMEI'] = hardwareidProp.imei ? hardwareidProp.imei : '';
              }
            }
            Object.keys(this.headersMap).forEach((key) => {
              dataToExport = {...dataToExport, [this.headersMap[key]]: this.network_data.get(report.id)[key]};
            });
            dataToExport = {...info, ...dataToExport};

            this.exportedData.push(dataToExport);
            this.exportedData.sort((device1, device2) => device1.Group > device2.Group ? 1 : device1.Group < device2.Group ? -1 : 0);
        }
        catch (err) {
          console.log('error:', err);
        }
      }));
    }
  }

  async getStreamChangeTime(deviceId, streamName) {
    const response = await API.get(
      Types.DEVICES,
      `${deviceId}/streams/${deviceId}.${streamName}/latest/`);
    if (!response['timestamp']) {
      return 'N/A';
    }
    const date = new Date(response['timestamp']);
    return Utils.getDateString(date);
  }
}
