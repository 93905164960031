<template>
    <div
      id="map-view"
      class="elevation-1 map-container"
      ref="mapWrap"
      :style="wrapStyles"
      :class="lang === 'he' ? 'right-filters' : ''"
    >
      <gmap-map
        ref="map"
        :center="center"
        :options="mapOptions"
        @click="mapClick"
        :style="mapStyles"
        style="width: 100%"
      >
        <gmap-info-window
          ref="infowindow"
          :options="infoOptions"
          :position="infoPosition"
          :opened="infoOpened"
          @closeclick="infoOpened = false"
        >
          <div v-html="infoContent"></div>
        </gmap-info-window>
        <gmap-cluster
          v-for="(district, districtIndex) in validDistricts"
          :key="districtIndex"
          :zoomOnClick="true"
          :minimumClusterSize="minimumClusterSize"
          :maxZoom="maxZoom"
        >
          <gmap-marker
            :key="index"
            v-for="(marker, index) in devicesByDistrict.get(district)"
            :options="getMarkerOptions(marker)"
            @click="clickMarker(marker)"
            @rightclick="showInfoWindow(marker)"
          ></gmap-marker>
        </gmap-cluster>
        <gmap-marker
          v-if="antennaLocation"
          :options="getAntennaOptions()"
        ></gmap-marker>
      </gmap-map>
      <!-- <div class="bottom-left-container">
        <v-tooltip top>
          <div slot="activator" class="btn-resizer mobile-hide" @click="setMapHeight">
            <i></i>
          </div>
          <span>{{ $t('Maximize Map') }}</span>
        </v-tooltip>
      </div> -->
      <div class="settings-btn-group">
        <div style="position:relative" >
          <v-tooltip top class="pa-1">
            <v-btn
              slot="activator"
              icon
              class="btn-custom-toggle background-white"
              :class="{satellite: isSatelliteMap}"
              @click="toggleSatelliteMap"
            >
              <v-icon>mdi-satellite</v-icon>
            </v-btn>
            <span>{{ $t('Satellite Map') }}</span>
          </v-tooltip>
          <v-tooltip top class="pa-1">
            <v-btn slot="activator" icon class="btn-custom-toggle background-white" @click="toggleSearch">
              <v-icon>search</v-icon>
            </v-btn>
            <span>{{ $t('Address search') }}</span>
          </v-tooltip >
          <v-text-field
            v-model="search_value"
            id="search-input"
            v-show="openSearch"
            append-icon="mdi-close"
            class="search-input"
            solo
            @click:append="toggleSearch"
            :placeholder="$t('Search address')"
            type="text"
          ></v-text-field>
          <v-tooltip top class="pa-1">
            <v-btn class="background-white" slot="activator" icon @click="toggleMapSettings">
              <v-icon>settings</v-icon>
            </v-btn>
            <span>{{ $t('Map Style') }}</span>
          </v-tooltip>
          <ExportData
            :data="device ? [...device] : [...markers]"
            :network_data="network_data"
            color="black"
            :text="false"
            :sourceTitle="device ? device.name : 'Network Management View'"
          />
        </div>
      </div>
      <div class="top-left-container">
        <div style="position:relative">
          <Legend v-if="showLegend" :more_legend_icons="more_legend_icons"/>
        </div>
      </div>

      <div class="map-settings" v-if="showSettings">

        <div>
          <span>{{ $t('Map Style') }}</span>
          <v-select
            :items="styleList"
            item-value="value"
            @change="setMapStyles"
            item-text="name"
            v-model="mapMode"
            attach
            menu-props="bottom, offsetY"
          ></v-select>
        </div>
      </div>
    </div>
</template>

<script>
import Vue from 'vue';
import * as deviceFactory from '@/pages/mapView/components/map/deviceFactory';
import config from './map.config';
import mapStyles from './mapStyles';
import * as VueGoogleMaps from 'vue2-google-maps';
import Legend from './legend.vue';
import PropUtils from '@/modules/PropUtils';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import API, { Types } from '@/modules/API';
import Utils from '@/modules/Utils';
import ExportData from '../ExportData.vue';

const defaultStyleSheet = [
  { value: 'defaultMode', name: 'Default' },
  { value: 'silverMode', name: 'Silver' },
  { value: 'nightMode', name: 'Night mode' }
];

export default {
  name: 'GoogleMap',
  components: {
    Legend,
    GmapCluster,
    ExportData
  },
  props: {
    project: Object,
    devices: Array,
    markerFocused: Object,
    lang: String,
    projectProperties: Array,
    cabinetCommunicationLevels: Array,
    fixtureCommunicationLevels: Array,
    editProp: Function,
    selectedDevices: Array,
    antennaLocation: Object,
    more_legend_icons: Array,
    network_data: Map
  },
  data() {
    return {
      search_value: '',
      digital_input_by_cabinet: new Map(),
      DISTRICT_COLUMN: 'District',
      showLegend: true,
      center: { lat: 45.508, lng: -73.587 },
      firstLoading: true,
      markers: [],
      devicesByDistrict: new Map(),
      districts: [],
      mapStyles: {},
      device: null,
      infoPosition: null,
      infoContent: null,
      infoOpened: false,
      infoOptions: {
        disableAutoPan: true,
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      langList: [
        { value: 'en', name: 'English' },
        { value: 'iw', name: 'Hebrew' },
        { value: 'es', name: 'Spanish' },
        { value: 'fr', name: 'French' },
        { value: 'de', name: 'German' },
        { value: 'ru', name: 'Russian' },
        { value: 'pt', name: 'Portuguese' },
        { value: 'ja', name: 'Japanese' },
        { value: 'ar', name: 'Arabic' }
      ],
      hasCustomMapStyles: false,
      styleList: defaultStyleSheet,
      mapMode: 'defaultMode',
      wrapStyles: { 'border-radius': '10px'},
      openSearch: false,
      showSettings: false,
      apiKey: config.TOKEN_GOOGLE,
      mapOptions: {
        disableDefaultUI: true,
        scaleControl: true,
        zoomControl: true,
        fullscreenControl: true,
        styles: mapStyles.defaultMode
      },
      isSatelliteMap: false,
      minimumClusterSize: 2,
      maxZoom: 15
    };
  },
  computed: {
    validDistricts(){
      return this.districts.filter((district) => this.devicesByDistrict.get(district) !== undefined);
    },
    projectDefaultLocation(){
      return this.$store.state.Props.project_default_location;
    }
  },
  mounted() {
    this.$emit('antennaChanged', null);
    this.initGoogleMap();
    this.initMapDistricts();
    this.initSearch();
    this.getProjectMapStyles();
    this.$nextTick(() => {
      this.setMarkerInformation();
    });
    this.setMapResize();
    this.devicesByDistrict.set('default', this.devices);
  },
  methods: {
    initGoogleMap(){
      Vue.use(VueGoogleMaps, {
        load: {
          key: this.apiKey,
          language: this.lang === 'he' ? 'iw' : 'en',
          libraries: ['places', 'visualization']
        }
      });
    },
    initMapDistricts(){
      this.districts = PropUtils.getProperty(this.projectProperties, 'dashboard.map_districts');
      this.districts = this.districts ? this.districts.value.concat(['default']) : ['default'];
      if (this.districts.length > 1){
        this.minimumClusterSize = 1;
      }
    },
    toggleSatelliteMap() {
      this.isSatelliteMap = !this.isSatelliteMap;
      this.isSatelliteMap 
        ? this.$refs.map.$mapObject.setMapTypeId('hybrid')
        : this.$refs.map.$mapObject.setMapTypeId('roadmap');
    },
    setMapResize() {
      const observer = new MutationObserver((m) => {
        this.handlerHeight(m[0].target.clientHeight + 'px');
      });
      const child = document.getElementsByClassName('map-container')[0];
      observer.observe(child, { attributes: true });
    },
    smoothZoom(map, level, cnt, mode) {
      if (cnt >= level) {
        return;
      } else {
        const z = map.addListener('zoom_changed', (e) => {
          google.maps.event.removeListener(z);
          this.smoothZoom(map, level, cnt + 1, true);
        });
        setTimeout(() => {
          map.setZoom(cnt);
        }, 80);
      }
    },
    mapClick() {
      this.infoOpened = false;
      this.$emit('deviceChanged', null);
      this.$emit('selectedChanged', []);
      this.$emit('antennaChanged', null);
    },
    getMarkerOptions(marker) {
      let icon;
      const buildIconSvg = (type, color) => {
        const trimmedColor = color.replace('#', '');
        if (type === 'FIXTURE'){
          const originalIcon = `data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' width='26' height='26' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23${trimmedColor};%7D%0A%3C/style%3E%3Ccircle id='Oval' class='st0' cx='12' cy='12' r='10'/%3E%3C/svg%3E%0A`;
          const circledIcon = `data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 24' width='26' height='26' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23${trimmedColor};%7D%0A%3C/style%3E%3Ccircle id='Oval' class='st0' cx='12' cy='12' r='10' stroke='blue' stroke-width='3'/%3E%3C/svg%3E%0A`;
          return this.selectedDevices.includes(marker.id) 
            ? circledIcon
            : originalIcon; 
        }
        if (type === 'CABINET'){
          const originalIcon = `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24" style='fill:%23${trimmedColor}' width="24px" height="24px"%3E%3Cg%3E %3Crect fill="none" height="24" width="24"/%3E%3Cpath d="M14.5,11l-3,6v-4h-2l3-6v4H14.5z M7,1h10c1.1,0,2,0.9,2,2v18c0,1.1-0.9,2-2,2H7c-1.1,0-2-0.9-2-2V3C5,1.9,5.9,1,7,1z M7,6 v12h10V6H7z"/%3E%3C/g%3E%3C/svg%3E`;
          return originalIcon;
        }
       
      };
      const getMarkerIcon = () => {
        const type = Utils.hasCabinetClass(marker.class_name)
          ? 'CABINET'
          : 'FIXTURE';

        if (marker['icon_color']) return buildIconSvg(type, marker['icon_color']);
        const quality_grade = this.network_data.has(marker.id) ? this.network_data.get(marker.id)['Communication_quality_grade'] : null;
        if (!quality_grade && quality_grade !== 0) return buildIconSvg(type, '#FFFFFF');

        let itemFound = {level: '#FFFFFF'};

        if (type === 'CABINET'){
          this.cabinetCommunicationLevels.find((item) => item.min_value <= quality_grade && quality_grade <= item.max_value);
        }else if (type === 'FIXTURE'){
          itemFound = this.fixtureCommunicationLevels.find((item) => item.min_value <= quality_grade && quality_grade <= item.max_value);
        }
        return buildIconSvg(type, itemFound.level);
      };
      icon = {
        url: getMarkerIcon(),
        scaledSize: new google.maps.Size(20, 20)
      };

      return {
        icon,
        clickable: true,
        draggable: false,
        position: { ...marker['meta.location'] }
      };
    },
    getAntennaOptions(){
      const icon = {
        url: config.icons.antenna,
        scaledSize: new google.maps.Size(25, 25)
      };
      return {
        icon,
        clickable: true,
        draggable: false,
        position: { lat: parseFloat(this.antennaLocation.lat), lng: parseFloat(this.antennaLocation.lng) }
      };
    },
    setMarkerInformation(mapBounds = true) {
      const markers = [];
      this.devicesByDistrict = new Map();
      this.devices.forEach((device) => {
        if (
          device['meta.location'] &&
          device['meta.commission'] &&
          device['meta.commission'].commissioned
        ) {
          if (device.has_valid_location) {
            this.assignDeviceToDistrict(device);
            markers.push(device);
          }   
        }
      });

      this.markers = markers;
      if (this.firstLoading && markers.length !== 0) {
        this.firstLoading = false;
      }
      if (mapBounds) {
        this.mapBounds();
      }
    },
    async addCabinetTimeInfo(device){
      const cabinetTime = { 
        on_time: '',
        off_time: ''
      };

      try{
        const activationStream = await API.get(
        'devices',
        `${device.id}/streams/${device.id}.control_next_activation/history/`, { since: '1days' });

        if (activationStream && activationStream.length){
          let lastDayValue = activationStream[activationStream.length - 1][1];
          lastDayValue = lastDayValue.replace(/'/g, '"');
          try{
            const parsedValue = JSON.parse(lastDayValue);
            cabinetTime.on_time = parsedValue.on_time;
            cabinetTime.off_time = parsedValue.off_time;
            device['cabinetTime'] = {...cabinetTime};
          }catch (e){
            console.log(e); 
            console.log(`Failed to parse cabinet time activation for cabinet ${device.id}`);
          }
        }

      }catch (e){}
    },
    clickMarker(marker) {
      if (marker.class_name){
        this.showInfoWindow(marker);
        this.device = marker;
        this.$emit('marker-click', marker);
      }
      this.$emit('panTo', marker);
    },
    convertDigitalInput(value){
      try{
        return JSON.parse(value.replaceAll(`'`, `"`).replaceAll('True', true).replaceAll('False', false));
      }catch (e){
        console.log('Error while parsing digital input value');
        return false;
      }
    },
    clearMarkers() {
      this.markers = [];
    },
    initSearch() {
      const input = document.getElementById('search-input');
      this.$refs.map.$mapPromise.then((m) => {
        Vue.set(this.wrapStyles, 'height', '68vh');
        Vue.set(this.mapOptions, 'zoomControlOptions', {
          position: google.maps.ControlPosition.LEFT_BOTTOM
        });
        Vue.set(this.mapOptions, 'streetViewControl', {
          position: google.maps.ControlPosition.LEFT_BOTTOM
        });
        const searchBox = new window.google.maps.places.SearchBox(input);
        searchBox.addListener('places_changed', () => {
          const places = searchBox.getPlaces();
          if (places.length !== 0) {
            const location = places[0].geometry.location;
            const position = {
              lat: location.lat(),
              lng: location.lng()
            };
            m.panTo(position);
            this.smoothZoom(m, 16, m.getZoom(), true);
          }
        });
        const thePanorama = m.getStreetView();
        thePanorama.addListener('visible_changed', () => {
          if (thePanorama.getVisible()) {
              this.showLegend = false;
          } else {
              this.showLegend = true;
          }
        });
        // restore the zoom level after the map is done scaling
        const idle = m.addListener('idle', () => {
          this.mapBounds();
          google.maps.event.removeListener(idle);
        });
      });
    },
    showInfoWindow(marker) {
      this.infoPosition = {...marker['meta.location']};
      this.infoContent = this.getMarkerText(marker);
      this.infoOpened = true;
    },
    assignDeviceToDistrict(device){
      const metaDevice = device['meta.device'];
      let deviceDistrict = metaDevice && metaDevice[this.DISTRICT_COLUMN];
      deviceDistrict = this.districts.includes(deviceDistrict) ? deviceDistrict : 'default';
      let temp = this.devicesByDistrict.get(deviceDistrict);
      temp ?  temp.push(device) : temp = [device];
      this.devicesByDistrict.set(deviceDistrict, [...temp]);
    },
    mapBounds() {
      this.$nextTick(() => {
        this.$gmapApiPromiseLazy().then(() => {
          const bounds = new google.maps.LatLngBounds();

          if (!this.markers.length) {

            bounds.extend(new google.maps.LatLng(this.projectDefaultLocation.lat, this.projectDefaultLocation.lng));
            // logic to change map zoom if we don`t have markers
            if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
              const extendPoint1 = new google.maps.LatLng(
                bounds.getNorthEast().lat() + 0.01,
                bounds.getNorthEast().lng() + 0.01
              );
              const extendPoint2 = new google.maps.LatLng(
                bounds.getNorthEast().lat() - 0.01,
                bounds.getNorthEast().lng() - 0.01
              );
              bounds.extend(extendPoint1);
              bounds.extend(extendPoint2);
            }
          } else {
            this.markers.forEach((marker) => {
              bounds.extend(new google.maps.LatLng(marker['meta.location'].lat, marker['meta.location'].lng));
            });
          }

          if (this.$refs.map) {
            this.$refs.map.fitBounds(bounds);
          }
        });
      });
    },
    getMarkerText(device) {
      const newDevice = deviceFactory.createDevice(device);
      return newDevice.getPopUpText();
    },
    setMapStyles(name) {
      if (this.hasCustomMapStyles) {
        const value = this.styleList.find((v) => v.value === name);
        if (value) {
          const map_styles = this.projectProperties.find(
            (v) => v.name === 'dashboard.map_styles'
          );
          Object.keys(map_styles.value).forEach((key) => {
            map_styles.value[key][0].default = key === this.mapMode;
          });
          localStorage.setItem('mapMode', value.value);
          this.editProp({
            _notify_msg: this.$t('Default style changed'),
            name: 'dashboard.map_styles',
            objectID: this.project.id,
            objectType: 'projects',
            value: map_styles.value
          });
          return Vue.set(this.mapOptions, 'styles', value.style);
        }
      } else {
        localStorage.setItem('mapMode', name);
        Vue.set(this.mapOptions, 'styles', mapStyles[name]);
      }
    },
    handlerHeight(height) {
      Vue.set(this.mapStyles, 'height', height);
    },
    // setMapHeight() {
    //   if (this.fullMap) {
    //     Vue.set(this.wrapStyles, 'height', '50vh');
    //   } else {
    //     Vue.set(this.wrapStyles, 'height', '90vh');
    //   }
    //   this.fullMap = !this.fullMap;
    // },
    toggleSearch() {
      this.openSearch = !this.openSearch;
      this.search_value = '';
    },
    toggleMapSettings() {
      this.showSettings = !this.showSettings;
    },
    toggleDragMarkers() {
      this.$store.state.MapSettings.deviceDrag = !this.$store.state.MapSettings
        .deviceDrag;
    },
    setDefaultProjectMapStyle() {
      const cachedMapMode = localStorage.getItem('mapMode');
      this.hasCustomMapStyles = false;
      this.styleList = defaultStyleSheet;
      if (cachedMapMode && !this.hasCustomMapStyles) {
        this.mapMode = cachedMapMode;
        Vue.set(this.mapOptions, 'styles', mapStyles[cachedMapMode]);
      } else {
        this.mapMode = 'defaultMode';
        Vue.set(this.mapOptions, 'styles', mapStyles['defaultMode']);
      }
    },
    getProjectMapStyles() {
      try {
        const styleList = [{ value: 'defaultMode', name: 'Default' }];
        const map_styles = this.projectProperties.find(
          (v) => v.name === 'dashboard.map_styles'
        );
        let defaultStyle = 'defaultMode';
        if (map_styles) {
          const { value } = map_styles;
          Object.keys(map_styles.value).forEach((key) => {
            if (value[key][0].default) {
              defaultStyle = key;
            }
            styleList.push({
              value: key,
              name: key,
              default: value[key][0].default,
              style: value[key][0].style[0]
            });
          });
        }
        if (styleList.length > 1) {
          this.hasCustomMapStyles = true;
          this.mapMode = defaultStyle;
          this.styleList = styleList;
          const def = styleList.find((v) => v.default === true);
          if (def) {
            localStorage.setItem('mapMode', def.value);
            Vue.set(this.mapOptions, 'styles', def.style);
          }
        } else {
          this.setDefaultProjectMapStyle();
        }
      } catch (e) {
        console.log('err', e);
        this.setDefaultProjectMapStyle();
      }
    }
  },
  watch: {
    devices(prev, next) {
      this.setMarkerInformation(true);
    },
    markerFocused() {
      const marker = this.markers.find((m) => m.id === this.markerFocused.id);
      if (marker || this.antennaLocation) {
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(new google.maps.LatLng(this.markerFocused['meta.location'].lat, this.markerFocused['meta.location'].lng));
        this.$refs.map.fitBounds(bounds);
      }
    },
    projectProperties(prev, next) {
      if (prev.length) {
        this.getProjectMapStyles();
      }
    },
    project() {
      localStorage.removeItem('mapMode');
    },
    antennaLocation() {
      if (this.antennaLocation && this.antennaLocation.lat){
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(new google.maps.LatLng(this.antennaLocation.lat, this.antennaLocation.lng));
        bounds.extend(new google.maps.LatLng(this.device['meta.location'].lat, this.device['meta.location'].lng));
        this.$refs.map.fitBounds(bounds);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.map-settings {
  padding: 10px;
  background-color: #fff;
  position: absolute;
  z-index: 2;
  top: 55px;
  right: 50px;
  width: 160px;

  .v-text-field {
    padding-top: 5px !important;
  }

  .v-select__slot {
    background-color: #fff;
  }

  .v-select__selections {
    padding-left: 5px;
  }
}

.search-input {
  background-color: #fff;
  border-radius: 8px;
  font-family: Roboto;
  width: 350px;
  position: absolute !important;
  z-index: 2;
  height: 35px;
  top: 1px;
  right: 5px;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin: 0px !important;
}

.v-text-field__details {
  height: 0px !important;
}

.active {
  color: green !important;
}

.satellite {
  color: #2196F3 !important;
}

.settings-btn-group {
  display: flex;
  position: absolute;
  top: 8px;
  right: 20px;
  button {
    margin: 0 5px 0 0;
  }
}

.editedDevice {
  box-shadow: 0 0 0 3px red;
  border-radius: 100%;
  position: absolute;
  opacity: 1 !important;
  z-index: 0;
}

.legend {
  position: absolute;
  left: 5px;
  top: 20px;
  z-index: 2;
}

.background-white {
  background-color: white;
}

.search-input {
  margin: 0 !important;
  height: 35px !important;
}

.bottom-left-container {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.top-left-container{
  display: flex;
  position: absolute;
  top: 8px;
  left: 8px;
  button {
    margin: 0 5px 0 0;
  }
}

.mobile-hide {
  @media (max-width: 480px) {
    display: none !important;
  }
}
</style>
